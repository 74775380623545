<template>
  <div>
      <iframe src="https://docs.google.com/document/d/e/2PACX-1vTBPaSuvLGql3W64XC_oFLOs7flsX2T6KmphoXrsDIiJmcWBYJvxDQgvO1H59m9JWNEhbwBCvrcqw8G/pub?embedded=true" width="100%" height="650px"></iframe>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>