<template>
  <div>

   <iframe src="https://docs.google.com/document/d/e/2PACX-1vQiVqIHi3qRGWp_5QYEcLmDf879Azt0JfkuaBJC6hnfOi-e_sAfBQ_SKOOeCjH_vOcMW8G5bqp1dR-k/pub?embedded=true" width="100%" height="650px"></iframe>   

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>