<template>
  <div>

   <iframe src="https://docs.google.com/document/d/e/2PACX-1vQK_XfwR1dxKIN1FKtg7ABT9Oy4GqgqhALOOiqnAnItHiYO0nLsgUoZTh4A6vWcAQ8eVrGJOO7Lz6kG/pub?embedded=true" width="100%" height="650px"></iframe>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>