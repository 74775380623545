import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Home from './components/Home.vue'
import About from './components/About.vue'
import Ourlecturers from './components/Ourlecturers'
import Timetable from './components/Timetable'
import Exam from './components/Exam'
import ExamResults from './components/ExamResults'
import Contact from './components/Contact'
import Notice from './components/Notice'
import Help from './components/Help'


// SUB timetable
import G1_5 from './components/G1_5'
import G6_9 from './components/G6_9'
import Ol from './components/Ol'
import Al from './components/Al'

import Courses from './components/Courses'

//authentication
import authentication from './components/authentication'
import Curtin from './components/Curtin'

//Modulepage First Day
import Tailoring from './components/Tailoring'
import Examalogin from './components/Examalogin'


//Modulepage    

import Modulepage from './components/Modulepage'
import English from './components/modulepages/English'

//Courses
import TailoringCourseFirstDay from './components/modulepages/TailoringCourseFirstDay'
import CurtainCourseFirstDay from './components/modulepages/CurtainCourseFirstDay'
import G16 from './components/modulepages/G16'

import Kali_4_saya from './components/modulepages/Kali_4_saya'
import Pleyad_saya from './components/modulepages/Pleyad_saya'
import Full_pleyad_saya from './components/modulepages/Full_pleyad_saya'
import Tight_skrt from './components/modulepages/Tight_skrt'
import Fishtail_skrt from './components/modulepages/Fishtail_skrt'
import kali_6_saya from './components/modulepages/kali_6_saya'

import Maggee_blouse from './components/modulepages/Maggee_blouse'
import Dart_sahitha_blouse from './components/modulepages/Dart_sahitha_blouse'
import Kort_kolaraya_sahitha_blouse from './components/modulepages/Kort_kolaraya_sahitha_blouse'
import Kota_blouse  from './components/modulepages/Kota_blouse'
import Princess_line_blouse from './components/modulepages/Princess_line_blouse'

import Pasal_sudu_gawuma from './components/modulepages/Pasal_sudu_gawuma'
import A_line_gawuma from './components/modulepages/A_line_gawuma'
import Shole_kolaraya_sahitha_gawuma from './components/modulepages/Shole_kolaraya_sahitha_gawuma'
import Ina_pahathata_damu_gawuma from './components/modulepages/Ina_pahathata_damu_gawuma'
import Nighty_patterns  from './components/modulepages/Nighty_patterns'
import Housecort_patterns  from './components/modulepages/Housecort_patterns'

import Lama_kamisaya from './components/modulepages/Lama_kamisaya'
import Ath_kota_kamisaya from './components/modulepages/Ath_kota_kamisaya'
import York_eka_sahitha_kamisaya from './components/modulepages/York_eka_sahitha_kamisaya'
import Ath_diga_kamisaya from './components/modulepages/Ath_diga_kamisaya'
import Pijama_kamisaya  from './components/modulepages/Pijama_kamisaya'

import Pirimi_lama_kota_kalisama from './components/modulepages/Pirimi_lama_kota_kalisama'
import Montessori_kota_kalisama  from './components/modulepages/Montessori_kota_kalisama'
import Pirimi_diga_kalisama from './components/modulepages/Pirimi_diga_kalisama'
import Panjab_kamisaya  from './components/modulepages/Panjab_kamisaya'

import Bra from './components/modulepages/Bra'

import Saree_hatta_warga  from './components/modulepages/Saree_hatta_warga'
import Samanya_sari_hattata  from './components/modulepages/Samanya_sari_hattata'
import Bracut_hattaya  from './components/modulepages/Bracut_hattaya'
import Hyneck_hattaya from './components/modulepages/Hyneck_hattaya'

import Bombay_cut_blouse  from './components/modulepages/Bombay_cut_blouse'

import Ath_nathi_hattaya  from './components/modulepages/Ath_nathi_hattaya'
import Pitupasa_paluwe_mosthara  from './components/modulepages/Pitupasa_paluwe_mosthara'
import Wiwida_kara_hada_warga  from './components/modulepages/Wiwida_kara_hada_warga'

import Ladaru_gawuma from './components/modulepages/Ladaru_gawuma'
import Fanel_cort_eka from './components/modulepages/Fanel_cort_eka'
import Thoppiya from './components/modulepages/Thoppiya'
import Sapaththu from './components/modulepages/Sapaththu'
import Lama_gawum_mosthara from './components/modulepages/Lama_gawum_mosthara'
import York_eka_sahitha_athkota_kamisaya from './components/modulepages/York_eka_sahitha_athkota_kamisaya'
import Kiyulat_saya from './components/modulepages/Kiyulat_saya'
import Ath_rahitha_hattaya from './components/modulepages/Ath_rahitha_hattaya'


//

const router =  new VueRouter({

    mode:'history',    //Remove # simble

    routes:[

        {path:"",component:Home},
        {path:"/about",component:About},
        {path:"/ourlecturers",component:Ourlecturers},
        {path:"/timetable",component:Timetable},
        {path:"/exam",component:Exam},
        {path:"/ExamResults",component:ExamResults},
        {path:"/contact",component:Contact},
        {path:"/notice",component:Notice},
        {path:"/help",component:Help},



        // SUB timetable
        {path:"/1_5",component:G1_5},
        {path:"/6_9",component:G6_9},
        {path:"/ol",component:Ol},
        {path:"/al",component:Al},
        {path:"/courses",component:Courses},

        //authentication
        {path:"/authentication",component:authentication},
        {path:"/examalogin",component:Examalogin},

        //Modulepage First Day
        {path:"/tailoring",component:Tailoring},
        {path:"/curtin",component:Curtin},



        //Modulepage
        // {path:"/modulepage",component:Modulepage},

        // Module login by un
        {
            name: 'Module Name',
            path: '/module/:id',
            component: Modulepage,
            children: [
                {path: '/module/english', component: English},
                {path: '/module/tailoring_course_first_day', component: TailoringCourseFirstDay},
                {path: '/module/curtain_course_first_day', component: CurtainCourseFirstDay},
                {path: '/module/g16', component: G16},

                {path: '/module/කෑලි_4_සාය', component: Kali_4_saya},
                {path: '/module/ප්ලෙයාඩ්_සාය', component: Pleyad_saya},
                {path: '/module/ෆුල්_ප්ලෙයාඩ්_සාය', component: Full_pleyad_saya},
                {path: '/module/ටයිට්_සාය_වර්ග', component: Tight_skrt},
                {path: '/module/ෆිශ්ටේල්_සාය', component: Fishtail_skrt},
                {path: '/module/කෑලි_6_සාය', component: kali_6_saya},
                
                {path: '/module/මැගී_බ්ලවුසය', component: Maggee_blouse},
                {path: '/module/ඩාට්_සහිත_බ්ලවුසය', component: Dart_sahitha_blouse},
                {path: '/module/කෝටි_කොලරය_සහිත_බ්ලවුසය', component: Kort_kolaraya_sahitha_blouse},
                {path: '/module/කොට_බ්ලවුසය', component: Kota_blouse},
                {path: '/module/Princess_line_blouse', component: Princess_line_blouse},
                
                
                {path: '/module/පාසල්_සුදු_ගවුම', component: Pasal_sudu_gawuma},
                {path: '/module/ඒ_ලයින්_ගවුම', component: A_line_gawuma},
                {path: '/module/ශෝල්_කොලරය_සහිත_ගවුම', component: Shole_kolaraya_sahitha_gawuma},
                {path: '/module/ඉන_පහතට_දැමූ_ගවුම', component: Ina_pahathata_damu_gawuma},
                {path: '/module/නයිටි_මෝස්තර', component: Nighty_patterns},
                {path: '/module/හවුස්කෝට්_මෝස්තර', component: Housecort_patterns},
                
                {path: '/module/ළමා_කමිසය', component: Lama_kamisaya},
                {path: '/module/අත්_කොට_කමිසය', component: Ath_kota_kamisaya},
                {path: '/module/යෝක්_එක_සහිත_කමිසය', component: York_eka_sahitha_kamisaya},
                {path: '/module/අත්_දිග_කමිසය', component: Ath_diga_kamisaya},
                {path: '/module/පිජාමා_කමිසය', component: Pijama_kamisaya},

                {path: '/module/පිරිමි_ළමා_කොට_කලිසම', component: Pirimi_lama_kota_kalisama},
                {path: '/module/මොන්ටිසෝරි_කොට_කලිසම', component: Montessori_kota_kalisama},
                {path: '/module/පිරිමි_දිග_කලිසම', component: Pirimi_diga_kalisama},
                {path: '/module/පන්ජාබ්_කමිසය', component: Panjab_kamisaya},

                {path: '/module/Bra ', component: Bra},
                {path: '/module/සාරි_හැට්ට_වර්ග', component: Saree_hatta_warga},
                {path: '/module/Samanya_hattata', component: Samanya_sari_hattata},
                {path: '/module/Bra_cut_hattaya', component: Bracut_hattaya},
                {path: '/module/හයිනෙක්_හැට්ටය', component: Hyneck_hattaya},

                {path: '/module/Bombay_cut_blouse', component: Bombay_cut_blouse},

                {path: '/module/අත්_නැති_හැට්ටය', component: Ath_nathi_hattaya},
                {path: '/module/පිටුපස_පලුවේ_මෝස්තර', component: Pitupasa_paluwe_mosthara},
                {path: '/module/විවිධ_කර_හැඩ_වර්ග', component: Wiwida_kara_hada_warga},
       
                {path: '/module/ළදරු_ගවුම', component: Ladaru_gawuma},
                {path: '/module/ෆැනෙල්_කෝට්_එක', component: Fanel_cort_eka},
                {path: '/module/තොප්පිය', component: Thoppiya},
                {path: '/module/සපත්තු  ', component: Sapaththu},
                {path: '/module/ළමා_ගවුම්_මෝස්තර', component: Lama_gawum_mosthara},
                {path: '/module/යෝක්_එක_සහිත_අත්කොට_කමිසය', component: York_eka_sahitha_athkota_kamisaya},
                {path: '/module/කියුලට්_සාය', component: Kiyulat_saya},
                {path: '/module/අත්_රහිත_හැට්ටය', component: Ath_rahitha_hattaya},



            ]
        }
    ]

});

export default router



