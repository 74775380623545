<template>
<div>

    <br>
    <!-- EXAM -->
    <div class="list-group">
  <a href="#" class="list-group-item active">
    <h2 class="list-group-item-heading"> EXAM RESULTS </h2>
  </a>

  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time"> Online Tailoring Course with Certificate | G11 Final Exam Result | 2022 </h4>
    <p class="list-group-item-text">Click Here</p>
  </a>

 

</div>



</div>
</template>

<script>
export default {

}
</script>

<style>

.list-group-item-heading-time, .list-group-item-text {
    text-align: center;
}

</style>