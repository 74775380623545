<template>
  <div>

    <h2>sapaththu</h2>
   <iframe src="https://docs.google.com/document/d/e/2PACX-1vQNFrAbS5z43inl2RWPX07e1JeqsjaOvP6y5RYlKOpuPHCkm0Da3p8ZyANYhSLmPEHzPshfN7Ir2ZZ8/pub?embedded=true" width="100%" height="650px"></iframe>   

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>