<template>
  <div>

      <iframe src="https://docs.google.com/document/d/e/2PACX-1vQTB5TKJVhxcofZhglgBH3xXk7yPgrHWhwLc3W_bX0uV3uVGLRK964GoCrG1T65gnzjM_vxNqtlX5YZ/pub?embedded=true" width="100%" height="650px"></iframe>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>