import Vue from 'vue'
import App from './App.vue' 
import Router from './routes.js'

import VueTyperPlugin from 'vue-typer' //Auto type text
Vue.use(VueTyperPlugin)

// Vue cookies
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

//Bootsrap
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

//Sweet sweetalert
// import swal from 'sweetalert';


Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

new Vue({
  el: '#app',
  render: h => h(App),
  router:Router
})
 