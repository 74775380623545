<template>
  <div>

     <iframe src="https://docs.google.com/document/d/e/2PACX-1vSII4Hj6iHBaFNu7j5EMHIL6SkZCCr2KKE6oVIo7RkJyXe4wrjSSqcZ7IcIc-0eVdUszGVm13hnDp3J/pub?embedded=true" width="100%" height="650px"></iframe> 

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>