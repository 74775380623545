<template>
<div>

  <iframe src="https://docs.google.com/document/d/e/2PACX-1vQsx7ajyvRwz6bMmtkdQOq6d6tCRjrAg-568At9dmsfn0DzdaDLk0m6lUcLoTpBPatciqmHjhlzob3_/pub?embedded=true" width="100%" height="650px"></iframe>

    <!-- <br> -->
    <!-- GRADE 1 -->
    <!-- <div class="list-group">
  <a href="#" class="list-group-item active">
    <h2 class="list-group-item-heading">1 ශ්‍රේණිය </h2>
  </a>

  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">දෙමළ</h4>
    <p class="list-group-item-text">සෙනසුරාදා ප.ව. 3.00-5.00</p>
  </a>
</div>

<br> -->

 <!-- GRADE 2 -->
    <!-- <div class="list-group">
  <a href="#" class="list-group-item active">
    <h2 class="list-group-item-heading">2 ශ්‍රේණිය </h2>
  </a>

  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">දෙමළ</h4>
    <p class="list-group-item-text">සෙනසුරාදා ප.ව. 3.00-5.00</p>
  </a>
</div>

<br> -->

 <!-- GRADE 3 -->
    <!-- <div class="list-group">
  <a href="#" class="list-group-item active">
    <h2 class="list-group-item-heading">3 ශ්‍රේණිය </h2>
  </a>

  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">දෙමළ</h4>
    <p class="list-group-item-text">සෙනසුරාදා ප.ව. 3.00-5.00</p>
  </a>
</div>

<br> -->

 <!-- GRADE 4 -->
    <!-- <div class="list-group">
  <a href="#" class="list-group-item active">
    <h2 class="list-group-item-heading">4 ශ්‍රේණිය </h2>
  </a>

  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">දෙමළ</h4>
    <p class="list-group-item-text">සෙනසුරාදා ප.ව. 3.00-5.00</p>
  </a>
</div>


<br> -->

 <!-- GRADE 5 -->
    <!-- <div class="list-group">
  <a href="#" class="list-group-item active">
    <h2 class="list-group-item-heading">5 ශ්‍රේණිය </h2>
  </a>

  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">දෙමළ</h4>
    <p class="list-group-item-text">සෙනසුරාදා ප.ව. 3.00 -5.00</p>
  </a>
</div> -->



</div>
</template>

<script>
export default {

}
</script>

<style>

.list-group-item-heading-time, .list-group-item-text {
    text-align: left;
}

</style>