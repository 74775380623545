<template>
  <div>

   <iframe src="https://docs.google.com/document/d/e/2PACX-1vRHxztvKk9BicMs3C7Qc05F_V1atOI8vC2IX5OMqEKB_qqUvNbNriZBasEODE-iCAIck_ZKuS4Ek-PF/pub?embedded=true" width="100%" height="650px"></iframe>   

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>