<template>
  <div>

      <iframe src="https://docs.google.com/document/d/e/2PACX-1vTl6zwbJCLS7ZW4FZeuY6elODt9GXygebKG-uKnXUYTbBiJuyFTbO_jME-g4o4smHLJP0pab6xyZQ01/pub?embedded=true" width="100%" height="650px"></iframe>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>