<template>
<div>

<iframe src="https://docs.google.com/document/d/e/2PACX-1vS6jGoFvLkt1Jnuuci8tWS1bOFg8LoU1CcTmT1JdxIU0b-YlVqRWvUfmw9iosuCWEOmVyRaSdVbEGfJ/pub?embedded=true" width="100%" height="650px"></iframe>

<!-- <br> -->

 <!-- GRADE 6 -->
    <!-- <div class="list-group">
  <a href="#" class="list-group-item active">
    <h2 class="list-group-item-heading"> 6 ශ්‍රේණිය </h2>
  </a>

  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">ගණිතය</h4>
    <p class="list-group-item-text">සෙනසුරාදා ප.ව. 1.00 - 3.00 </p>
  </a>

  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">විද්‍යාව</h4>
    <p class="list-group-item-text">සෙනසුරාදා පෙ.ව. 8.30 - 10.30</p>
  </a>

    <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">ඉංග්‍රීසි</h4>
    <p class="list-group-item-text">ඉරිදා ප.ව. 3.00 - 5.00</p>
  </a>

    <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">සිංහල</h4>
    <p class="list-group-item-text">ඉරිදා ප.ව. 1.00 - 3.00</p>
  </a>

    <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">දෙමළ</h4>
    <p class="list-group-item-text">සෙනසුරාදා පෙ.ව. 10.30 – 12.30</p>
  </a>

   <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">ICT</h4>
    <p class="list-group-item-text">ඉරිදා ප.ව. 3.00 - 5.00</p>
  </a>

    <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">චිත්‍ර</h4>
    <p class="list-group-item-text">සිකුරාදා ප.ව. 2.30 - 4.30</p>
  </a>

  </div>

<br> -->

<!-- GRADE 7 -->
    <!-- <div class="list-group">
  <a href="#" class="list-group-item active">
    <h2 class="list-group-item-heading"> 7 ශ්‍රේණිය </h2>
  </a>

  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">ගණිතය</h4>
    <p class="list-group-item-text">සෙනසුරාදා පෙ.ව. 8.30 - 10.30</p>
  </a>

  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">විද්‍යාව</h4>
    <p class="list-group-item-text">සෙනසුරාදා ප.ව. 3.00 - 5.00</p>
  </a>

    <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">ඉංග්‍රීසි</h4>
    <p class="list-group-item-text">සෙනසුරාදා ප.ව. 1.00 - 3.00</p>
  </a>

    <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">සිංහල</h4>
    <p class="list-group-item-text">ඉරිදා ප.ව. 1.00 - 3.00</p>
  </a>

    <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">දෙමළ</h4>
    <p class="list-group-item-text">සෙනසුරාදා පෙ.ව. 10.30 - 12.30</p>
  </a>

  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">ICT</h4>
    <p class="list-group-item-text">ඉරිදා ප.ව. 3.00 - 5.00</p>
  </a>

   <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">චිත්‍ර</h4>
    <p class="list-group-item-text">සිකුරාදා ප.ව. 2.30 - 4.30</p>
  </a>


</div> -->

<!-- GRADE 8 -->
    <!-- <div class="list-group">
  <a href="#" class="list-group-item active">
    <h2 class="list-group-item-heading"> 8 ශ්‍රේණිය </h2>
  </a>

  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">ගණිතය</h4>
    <p class="list-group-item-text">සෙනසුරාදා පෙ.ව. 10.30 - 12.30</p>
  </a>

  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">විද්‍යාව</h4>
    <p class="list-group-item-text">සෙනසුරාදා ප.ව. 1.00 - 3.00</p>
  </a>

    <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">ඉංග්‍රීසි</h4>
    <p class="list-group-item-text">සෙනසුරාදා ප.ව. 3.00 - 5.00</p>
  </a>


    <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">සිංහල</h4>
    <p class="list-group-item-text">ඉරිදා ප.ව. 3.00 - 5.00</p>
  </a>

    <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">දෙමළ</h4>
    <p class="list-group-item-text">සෙනසුරාදා පෙ.ව. 8.30 - 10.30</p>
  </a>

  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">ICT</h4>
    <p class="list-group-item-text">ඉරිදා ප.ව. 3.00 - 5.00</p>
  </a>

   <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">චිත්‍ර</h4>
    <p class="list-group-item-text">සිකුරාදා ප.ව. 2.30 - 4.30</p>
  </a>

</div>

<br>
 -->
<!-- GRADE 9 -->
    <!-- <div class="list-group">
  <a href="#" class="list-group-item active">
    <h2 class="list-group-item-heading"> 9 ශ්‍රේණිය </h2>
  </a>

  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">ගණිතය</h4>
    <p class="list-group-item-text">සෙනසුරාදා ප.ව. 3.00 - 5.00</p>
  </a>

  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">විද්‍යාව</h4>
    <p class="list-group-item-text">සෙනසුරාදා පෙ.ව. 10.30 - 12.30</p>
  </a>

    <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">ඉංග්‍රීසි</h4>
    <p class="list-group-item-text">ඉරිදා ප.ව. 1.00 - 3.00</p>
  </a>


    <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">සිංහල</h4>
    <p class="list-group-item-text">අඟහරුවාදා ප.ව. 2.30 - 4.30</p>
  </a>

    <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">දෙමළ</h4>
    <p class="list-group-item-text">සෙනසුරාදා ප.ව. 1.00 - 3.00</p>
  </a>

    <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">චිත්‍ර</h4>
    <p class="list-group-item-text"></p>
  </a>

    <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">නැටුම්</h4>
    <p class="list-group-item-text"></p>
  </a>

  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">ICT</h4>
    <p class="list-group-item-text">ඉරිදා ප.ව. 3.00 - 5.00</p>
  </a>

   <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">චිත්‍ර</h4>
    <p class="list-group-item-text">සිකුරාදා ප.ව. 2.30 - 4.30</p>
  </a>


</div>



 -->




</div>
</template>

<script>
export default {

}
</script>

<style>

.list-group-item-heading-time, .list-group-item-text {
    text-align: left;
}

</style>