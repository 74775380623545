<template>
  <div>

      <iframe src="https://docs.google.com/document/d/e/2PACX-1vTsd6zOJwRQImUcMhC1gwfvVn89yYF4Gu9q6C6o_VcBRvDFtBsgR7mWnZZf7o97uHD-IM7UiviZcH7n/pub?embedded=true" width="100%" height="650px"></iframe>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>