<template>
  <div>
      <iframe src="https://docs.google.com/document/d/e/2PACX-1vTbygWGUDRF_Af157pl3PBFCC6IgLUhl7a_DGlFEc2xjWO2Is2CoO1RN0l50x57LKWMDZ4m8MOs9Bcz/pub?embedded=true" width="100%" height="650px"></iframe>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>