<template>
  <div>

  <iframe src="https://docs.google.com/document/d/e/2PACX-1vTOczEDwv9hXPZC6MoIybINAFV9AvRorkcA3LrsRTOdW2ODtwBfnNz_ikH9ZAK1tFyGGJ_VCUoblhat/pub?embedded=true" width="100%" height="650px"></iframe>    

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>