<template>
  <div>

      <iframe src="https://docs.google.com/document/d/e/2PACX-1vRoj7g4iTq-0efJKLN6J88icc9amjwXW3eyoJ8yTGFxUEm0DepnzTt58dgVaUhF1F69KW9lu-5vhwvc/pub?embedded=true" width="100%" height="650px"></iframe>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>