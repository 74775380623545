<template>
  <div>

      <iframe src="https://docs.google.com/document/d/e/2PACX-1vT6RZImdXvbGhdhEqMU2Hbanx3UG-L9QpRRdLYHIwLCBFfArVprCWd8pZy5F0_xB3_JZEe8C7UYn3PW/pub?embedded=true" width="100%" height="650px"></iframe>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>