<template>
  <div>

     <iframe src="https://docs.google.com/document/d/e/2PACX-1vRIrn5CsiRsYtPXr5te4bEekBzAxjUo5olOL0iPrONNV_FjEYiZk9HLWKMaf3X0lcmos8Df2FQ_ICYm/pub?embedded=true" width="100%" height="650px"></iframe>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>