<template>
  <div>

     <iframe src="https://docs.google.com/document/d/e/2PACX-1vQ1omnqiVg1BifKYO5A1AzTCUvSyN3UeRn6bnpniwFrrCozn_OIQLDt5_KafFoSPs5jvLFPvN1pTn2L/pub?embedded=true" width="100%" height="650px"></iframe> 

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>