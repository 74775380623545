<template>
  <div>

   <iframe src="https://docs.google.com/document/d/e/2PACX-1vSe7FyV7lb4KyfnO09kuATKV7DPFUAXNT9mPfholeUTlO6FPUuArIfFI2aZKcXddLtTxgdCFv07SPWG/pub?embedded=true" width="100%" height="650px"></iframe>   

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>