<template>
  <div>

      <iframe src="https://docs.google.com/document/d/e/2PACX-1vS67_TYBZGnbnfSmuTuY7S1ZLO6mvxLD_HnSXVsvN7wNHGWvJs07A9wi_Auw-_H9JXTdXhM1-56HIKQ/pub?embedded=true" width="100%" height="650px"></iframe>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>