<template>
  <div>

      <iframe src="https://docs.google.com/document/d/e/2PACX-1vSwpevFsya7bMe_6oJN2PIbj3ljmYZTH1Mz7Q-CF8lEFQoHc6BYQIgRbWvJy2sMpxifrAoo3dWvpWGs/pub?embedded=true" width="100%" height="650px"></iframe>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>