<template>
  <div>

    <iframe src="https://docs.google.com/document/d/e/2PACX-1vT5We84-gwb1Jk7BD-ut5HsHZsQtpzltNbuld9-yeljmXaNQNuP4pXz62OwzCt99_VLPBnqrchRFVfS/pub?embedded=true" width="100%" height="650px"></iframe>  

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>