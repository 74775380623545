<template>
  <div>

<iframe src="https://docs.google.com/document/d/e/2PACX-1vT8ztyJGRB8TJhR93jLd3og4zJ9Gj8pcU2lwtfeVvJfWS6lwCtBGwjHyrPxlN2mnZfhRNmCxOyvgXsF/pub?embedded=true" width="100%" height="650px"></iframe>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>