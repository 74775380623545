<template>
  <div id="app">

    <navbar> </navbar>
    <vnb />
    <router-view></router-view>

     <!-- Footer-->
         <hr size="10px">
        <footer class="footer py-4">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-4 text-lg-start">  &copy; Shilpa Academy of Higher Education <br> All Rights Reserved.</div>
                    <div class="col-lg-4 my-3 my-lg-0">
                        <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-twitter"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.facebook.com/shilpaacademy" target="_blank"><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-linkedin-in"></i></a>
                    </div>
                    <div class="col-lg-4 text-lg-end">
                        <a class="link-dark text-decoration-none me-3" href="#!">Powerd by : DanumaLK Technologies </a>
                        <a class="link-dark text-decoration-none" href="https://lakshanvidanapathirana.vercel.app/contact" target="_blank"> <br> Design & Develop by : Lakshan Vidanapathirana </a>
                    </div>
                </div>
            </div>
        </footer>
        <!-- Footer End-->
     
  </div>
</template>

<script>
//  import Navbar from './components/Navbar.vue'
 import VueNavbar from './components/Vuenavbar.vue'

export default {
  
  components:{
    // 'navbar':Navbar,
    'vnb': VueNavbar
  }
}



</script>
