<template>
  <div>

      <iframe src="https://docs.google.com/document/d/e/2PACX-1vT_GL8tS1b_koFYCapHvFStBLUWL5iTnEfeQ3L7-QIsLO2Te7YXYHH5ZQzxhY6sCNvMHHNJP0a8By_w/pub?embedded=true" width="100%" height="650px"></iframe>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>