<template>
  <div>

    <iframe src="https://docs.google.com/document/d/e/2PACX-1vSVlaRKu5AqkZFJ-L4srKeJ6prmUTnSRZJZhTZra8ivjksrelQGG84vXyuoA8EpFF0bX2kwVeOLOdql/pub?embedded=true" width="100%" height="650px"></iframe>  

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>