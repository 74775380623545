<template>
  <div>
    <br />

    <a href="#" class="list-group-item active">
    <h2 class="list-group-item-heading">A/L COMMERCE STREAM </h2>
  </a>

    <br />

    <section class="img-list">
      <ol class="img-list-parent">
        <li class="img-list-item">
          <div class="img-list-gutter">
            <div class="img-list-image">
              <img class="img-list-src" src="images/lecture.png" />
            </div>

            <div class="img-list-text">
              <h3>JMS JAYASUNDARA</h3>
              <p>ECONOMICS</p>
            </div>
          </div>
          
        </li>


        <li class="img-list-item">
          <div class="img-list-gutter">
            <div class="img-list-image">
              <img class="img-list-src" src="images/lecture.png" />
            </div>

            <div class="img-list-text">
              <h3>DAYASENA KAPURUSINGHE</h3>
              <p>ACCOUNTING</p>
            </div>
          </div>
        </li>


        <li class="img-list-item">
          <div class="img-list-gutter">
            <div class="img-list-image">
              <img class="img-list-src" src="images/lecture.png" />
            </div>

            <div class="img-list-text">
              <h3>AMILA SAMPATH</h3>
              <p>BUSINESS STUDIES</p>
            </div>
          </div>
        </li>

        <br />

         <a href="#" class="list-group-item active">
    <h2 class="list-group-item-heading">A/L ART STREAM </h2>
  </a>


        <br />

        <li class="img-list-item">
          <div class="img-list-gutter">
            <div class="img-list-image">
              <img class="img-list-src" src="images/lecture.png" />
            </div>

            <div class="img-list-text">
              <h3>ATHULA VIDANAPATHIRANA</h3>
              <p>SINHALA</p>
            </div>
          </div>
        </li>

        <li class="img-list-item">
          <div class="img-list-gutter">
            <div class="img-list-image">
              <img class="img-list-src" src="images/lecture.png" />
            </div>

            <div class="img-list-text">
              <h3>ANANDA PEIRIS</h3>
              <p>POLITICAL SCIENCE</p>
            </div>
          </div>
        </li>

        <li class="img-list-item">
          <div class="img-list-gutter">
            <div class="img-list-image">
              <img class="img-list-src" src="images/lecture.png" />
            </div>

            <div class="img-list-text">
              <h3>A. PUNNANANDA THERO</h3>
              <p>BUDDHIST CIVILIZATION</p>
            </div>
          </div>
        </li>

        <li class="img-list-item">
          <div class="img-list-gutter">
            <div class="img-list-image">
              <img class="img-list-src" src="images/lecture.png" />
            </div>

            <div class="img-list-text">
              <h3>SANGEETH RAJAPAKSHA</h3>
              <p>MEDIA</p>
            </div>
          </div>
        </li>

        <li class="img-list-item">
          <div class="img-list-gutter">
            <div class="img-list-image">
              <img class="img-list-src" src="images/lecture.png" />
            </div>

            <div class="img-list-text">
              <h3>LALINDA SENEVIRATHNA</h3>
              <p>GENERAL ENGLISH</p>
            </div>
          </div>
        </li>

        <li class="img-list-item">
          <div class="img-list-gutter">
            <div class="img-list-image">
              <img class="img-list-src" src="images/lecture.png" />
            </div>

            <div class="img-list-text">
              <h3>SAMITH MANUKULASOORIYA</h3>
              <p>HISTORY</p>
            </div>
          </div>
        </li>

        <li class="img-list-item">
          <div class="img-list-gutter">
            <div class="img-list-image">
              <img class="img-list-src" src="images/lecture.png" />
            </div>
            <div class="img-list-text">
              <h3>DANCING</h3>
              <p>
                SAMANTHA KUMARA
              </p>
            </div>
          </div>
        </li>
        <li class="img-list-item">
          <div class="img-list-gutter">
            <div class="img-list-image">
              <img class="img-list-src" src="images/lecture.png" />
            </div>
            <div class="img-list-text">
              <h3>AGRICULTURE</h3>
              <p>
                HEMANTHA P. BANDARA
              </p>
            </div>
          </div>
        </li>
      </ol>
    </section>

  


  </div>
</template>

<script>
export default {};
</script>

<style>
.stream, .list-group-item-heading {
  text-align: center;
  /* color: #0D6EFD; */
}

body {
  padding: 15px;
}

.img-list {
  margin: 0 auto;
}

.img-list ol,
.img-list ul {
  list-style: none;
}

.img-list-gutter {
  padding: 0;
}

.img-list-item {
  border-bottom: 1px solid #c2c2c2;
  padding-bottom: 2em;
  margin-bottom: 2em;
}

.img-list-text {
  padding: 1em 0;
}

@media (min-width: 600px) {
  .image-list-gutter {
    display: table;
  }

  .img-list-image,
  .img-list-text {
    display: table-cell;
    vertical-align: top;
  }

  .img-list-text {
    padding: 0 0 0 38px;
  }

  .img-list-image {
    min-width: 280px;
  }

  .img-list-src {
    width: 100%;
    max-width: 356px;
  }
}

@media (min-width: 764px) {
  .img-list-text {
    padding: 0 0 0 48px;
  }

  .img-list-image {
    min-width: 356px;
    max-width: 356px;
  }
}
</style>