<template>
  <div class="container">
      <div class="row my-3 p-0">
         <div class="col-md-3"></div>
         <div class="col-md-6 d-flex " >
            <router-link v-for="mod in userdata.module" :key="mod" :to="`/module/${mod}`">
               <button class="btn btn-primary mx-2 text-capitalize">{{mod}}</button>
            </router-link>
         </div>
         <div class="col-md-3"></div>
      </div>
      <div class="p-2 bg-light rounded">
         Module Content
         <router-view />
      </div>
  </div>
</template>

<script>
import json from './userDB.json'

export default {
   data() {
      return {
         username: '',
         userdata: '',
      }
   },
   created() {
      if(this.$cookies.isKey("username")) {
         this.username = this.$cookies.get("username")

         this.userdata = json.find(({username}) => username === this.username )
      } else {
         this.$router.push("/authentication")
      }
   }
}
</script>

<style>

</style>