<template>
  <div>

   <iframe src="https://docs.google.com/document/d/e/2PACX-1vRiA1b-YDP1_QmUdKTTqXqZy0oAKpWtaBdVdxFZf17v1X3iL6IhG4w3sn8iwREQ2AdtaLkkZzyzBrVQ/pub?embedded=true" width="100%" height="650px"></iframe>   

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>