<template>
  <div>

     <iframe src="https://docs.google.com/document/d/e/2PACX-1vTzcafFv8F8gGkvzFJJsCisALQdXLEWF4UESzM53qHzBc5a7SaI0o5yRnI85mDrOXMVUFZGmV6tyoTn/pub?embedded=true" width="100%" height="650px"></iframe> 

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>