<template>
  <div>

<iframe src="https://docs.google.com/document/d/e/2PACX-1vSi_Qvo20ATMDx2ceIy1ww4S8y0PzrEY5Sw42NdRe9m56VGLBmz3iRJlhOuk8W1PENa7cIZ_wVzQY0e/pub?embedded=true" width="100%" height="650px"></iframe>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>