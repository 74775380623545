<template>
  <div>

     <iframe src="https://docs.google.com/document/d/e/2PACX-1vRGExrZGqqMmbEixiBL3MJM5g-jjOS6BN2ahwN1N0Ud4C4cXBBGhkon3UwCdYABiltFCa6Xt4p24Kgv/pub?embedded=true" width="100%" height="650px"></iframe> 

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>