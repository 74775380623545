<template>
  <div>

  <iframe src="https://docs.google.com/document/d/e/2PACX-1vTgDhqJ0frTTu6RdecQq0urKe0ikwa2RSEHCcCWrg5dRfzzdCTPKyJqd-NDilo2GwpzUCtyOrEw-mhU/pub?embedded=true" width="100%" height="650px"></iframe>      

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>