<template>
  <div>
    
     <iframe src="https://docs.google.com/document/d/e/2PACX-1vSV8PwwEEowRvAGt66XOu0HHlj_qZg_Xo1kWPpnu_jUSf4T3Hp2NnR5Nkq1o5z6BaLtpKdVzzqTnfkX/pub?embedded=true" width="100%" height="650px" ></iframe>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>