<template>
  <div>

     <iframe src="https://docs.google.com/document/d/e/2PACX-1vSjJ0tfH_7P2WxnIc7BmUY_G93XOTJa5wQR_hDOfUPNkZl9q7ESUZg2Qdd2WhSrqet6r6tddB93Kbcb/pub?embedded=true" width="100%" height="650px"></iframe> 

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>