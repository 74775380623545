<template>
  <div>

      <iframe src="https://docs.google.com/document/d/e/2PACX-1vT3gm_Y1T4Z-DjNGnAdHqjEwjMuzubsAxaccC0Ct59vKRAd4-geANGf3Bjk-XCApjKArwuWrbc_adN7/pub?embedded=true" width="100%" height="650px"></iframe>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>