<template>
  <div>

      <iframe src="https://docs.google.com/document/d/e/2PACX-1vSF7duhEkDFJP-3SStYcrWn6ODdpQ-rAlO1pXVK-EADXv2W1SokerCtMzxw6fOvD535XruRNMmX355A/pub?embedded=true" width="100%" height="650px"></iframe>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>