<template>
  <div>

     <iframe src="https://docs.google.com/document/d/e/2PACX-1vSw3xynrVUsbe_KcEE743Tr_VzQzHLSRJaSmdlGLhqO7cfuzteXqtCZVvOWdtf9WCznLo-EZLm-fdzU/pub?embedded=true" width="100%" height="650px"></iframe> 

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>