<template>
<div>

  <iframe src="https://docs.google.com/document/d/e/2PACX-1vTlDgn-7EqJbi0jf6xOM4GSir-Zl51YRjdGYaEsVByK_jlIg9uQ8ihBqiRDSW3X9no8j8xZus6hmi-f/pub?embedded=true" width="100%" height="650px"></iframe>

    <!-- <br> -->
    <!-- COMMERCE STREAM -->
    <!-- <div class="list-group">
  <a href="#" class="list-group-item active">
    <h2 class="list-group-item-heading"> COMMERCE STREAM </h2>
  </a>

  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">ආර්ථික විද්‍යාව</h4>
    <p class="list-group-item-text">2023 A/L - සෙනසුරාදා පෙ.ව. 8.30 - 10.30</p>
    <p class="list-group-item-text">2022 A/L - සෙනසුරාදා පෙ.ව. 10.30 - 04.30 (Theory and Revision)</p>
  </a>

</div>


<a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">ගිණුම්කරණය </h4>
    <p class="list-group-item-text">2023 A/L - ඉරිදා පෙ.ව. 8.30 - 10.30</p>
    <p class="list-group-item-text">2022 A/L - ඉරිදා පෙ.ව. 10.30 - 04.30 (Theory and Revision)</p>
  </a>


  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">ව්‍යාපාර අධ්‍යයනය </h4>
    <p class="list-group-item-text">2022 A/L - ඉරිදා පෙ.ව. 8.30 - 10.30</p>
    <p class="list-group-item-text">2023 A/L - ඉරිදා පෙ.ව. 10.30 - 12.30</p>
  </a>

<br> -->

 <!-- ART STREAM -->
    <!-- <div class="list-group">
  <a href="#" class="list-group-item active">
    <h2 class="list-group-item-heading">ART STREAM </h2>
  </a>

  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">සිංහල</h4>
    <p class="list-group-item-text">2022 A/L - සෙනසුරාදා පෙ.ව. 10.30 - 12.30</p>
    <p class="list-group-item-text">2023 A/L - සෙනසුරාදා ප.ව. 1.00 - 3.00</p>
  </a>


  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">දේශපාලන විද්‍යාව</h4>
    <p class="list-group-item-text">2022 A/L - සෙනසුරාදා පෙ.ව. 8.30 - 10.30</p>
    <p class="list-group-item-text">2023 A/L - සෙනසුරාදා ප.ව. 10.30 - 12.30</p>
  </a>


  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">බෞද්ධ ශිෂ්ටාචාරය</h4>
    <p class="list-group-item-text">2023 A/L - ඉරිදා ප.ව. 12.00 - 2.00</p>
  </a>

  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time"> භූගෝල විද්‍යාව</h4>
    <p class="list-group-item-text">2023 A/L - ඉරිදා පෙ.ව. 8.30 - 10.30</p>
  </a>

<a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time"> මාධ්‍ය අධ්‍යයනය</h4>
    <p class="list-group-item-text">2023 A/L - සෙනසුරාදා පෙ.ව. 10.30 - 12.30</p>
     <p class="list-group-item-text">2022 A/L - සෙනසුරාදා පෙ.ව. 8.30 - 10.30</p>
    <p class="list-group-item-text">2022 A/L - සෙනසුරාදා  ප.ව. 1.00 - 3.00 Revision </p>
  </a>

<a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time"> චිත්‍ර</h4>
     <p class="list-group-item-text">2023 A/L - සිකුරාදා  ප.ව. 2.30 - 4.30</p>
    <p class="list-group-item-text">2022 A/L - සිකුරාදා  ප.ව. 2.30 - 4.30 Revision </p>
  </a>

  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time"> ඉතිහාසය</h4>
    <p class="list-group-item-text">2023 A/L - බදාදා  ප.ව. 2.30 - 4.30</p>
  </a>


<a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time"> නර්තනය</h4>
    <p class="list-group-item-text">2023 A/L -</p>
    <p class="list-group-item-text"> </p>
  </a>





</div>

<br> -->

 <!-- Common -->
    <!-- <div class="list-group">
  <a href="#" class="list-group-item active">
    <h2 class="list-group-item-heading"> COMMON STREAM</h2>
  </a>

  <a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">GENERAL ENGLISH</h4>
    <p class="list-group-item-text">2023 A/L - බ්‍රහස්පතින්දා ප.ව. 2.30 - 4.30</p>
    <p class="list-group-item-text">2023 A/L - බදාදා ප.ව. 2.30 - 4.30</p>
  </a>

<a href="#" class="list-group-item">
    <h4 class="list-group-item-heading-time">කෘෂි විද්‍යාව</h4>
    <p class="list-group-item-text">202_ A/L - ඉරිදා පෙ.ව. 8.30 - 12.30</p>
  </a>

</div> -->



</div>
</template>

<script>
export default {

}
</script>

<style>

.list-group-item-heading-time, .list-group-item-text {
    text-align: left;
}


</style>