<template>
  <div>
      <iframe src="https://docs.google.com/document/d/e/2PACX-1vRX1SWSAeVEDjY4J6mVyba7g7n04myrseUMLkqocax1woC3e-ezeDuU3DDdsyzMhk4j_XDUd4znhGg4/pub?embedded=true" width="100%" height="650px"></iframe>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>