<template>
  <div>

   <iframe src="https://docs.google.com/document/d/e/2PACX-1vRxWqHHQn4Ni_GP0QfkH-bfjfxNE--r-1EGesu8qKnMif0YXSkhzZR54nCP-P9NSIhOkTDJby4tAAKR/pub?embedded=true" width="100%" height="650px"></iframe>   

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>