<template>
<div>
  <form ref="form" @submit.prevent="sendEmail">
    <div>
      <html lang="en">
        <head>
          <title>Contact Us</title>
        </head>
        <body>
          <div class="container contact">
            <div class="row">
              <div class="col-md-3">
                <div class="contact-info">
                  <img
                    src="https://image.ibb.co/kUASdV/contact-image.png"
                    alt="image"
                  />
                  <h2>Contact Us</h2>
                  <h4>
                    Get in touch with Shilpa Academy via the form we would love to hear from you! <br> <br> TP : +94718133090
                  </h4>
                </div>
              </div>
              <div class="col-md-9">
                <div class="contact-form">
                  <div class="form-group">
                    <label class="control-label col-sm-2" for="fname"
                      >Name:</label
                    >
                    <div class="col-sm-10">
                      <input
                        type="text"
                        v-model="name"
                        class="form-control"
                        id="fname"
                        placeholder="Enter Name"
                        name="from_name"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="control-label col-sm-2" for="email"
                      >Email Address:</label
                    >
                    <div class="col-sm-10">
                      <input
                        type="email"
                        v-model="email"
                        class="form-control"
                        id="email"
                        placeholder="Enter email"
                        name="from_name"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="control-label col-sm-2" for="comment"
                      >Message:</label
                    >
                    <div class="col-sm-10">
                      <textarea
                        name="message"
                        v-model="message"
                        class="form-control"
                        rows="5"
                        id="comment"
                      ></textarea>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="col-sm-offset-2 col-sm-10">
                      <button
                        type="submit"
                        value="Send"
                        class="btn btn-default"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </body>
      </html>
    </div>
  </form>
  
</div>
</template>

<script>
import emailjs from "emailjs-com";
import swal from "sweetalert";

export default {
  data() {
    return {
      name: "",
      email: "",
      message: "",
    };
  },
  methods: {
	/**
	 * Set all data to initial state
	 */
	resetData() {
		Object.assign(this.$data, this.$options.data());
	},
	/**
	 * Send email using
	 * Email.js library
	 */
    sendEmail() {
      if (this.name && this.email && this.message) {
        emailjs
          .sendForm(
            "service_x17dai7",
            "template_br06i9h",
            this.$refs.form,
            "user_cCNf4hkrZa8KQdS8fzIJ6"
          )
          .then(
            (result) => {
              console.log("SUCCESS!", result.text);
            },
            (error) => {
              console.log("FAILED...", error.text);
            }
          );

        swal(
          "YOUR MESSAGE WAS SENT SUCCESSFULLY. PLEASE CHECK YOUR EMAIL INBOX FOR REPLY.",
          "THANK YOU! 😊 ",
          "success"
        );

		// Reset data to init state
		this.resetData();
      } else {
        swal("Error.", "Please fill all fields", "error");
      }
    },
  },
};
</script>

<style scoped>
body {
  /* background-color: #25274d; */
  top: 0%;
  left: 0%;
  width: 100%;
  height: 745px;
  background-color: rgb(255, 255, 255);
}
.contact {
  padding: 4%;
  height: 400px;
}
.col-md-3 {
  background: #0D6EFD;
  padding: 4%;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.contact-info {
  margin-top: 10%;
}
.contact-info img {
  margin-bottom: 15%;
}
.contact-info h2 {
  margin-bottom: 10%;
}
.col-md-9 {
  background: #fff;
  padding: 3%;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.contact-form label {
  font-weight: 600;
}
.contact-form button {
  background: #000000;
  color: #fff;
  font-weight: 600;
  width: 25%;
}
.contact-form button:focus {
  box-shadow: none;
}

.btn {
  margin-top: 50px;
}
</style>
