<template>
<div>

    <br>
    <!-- GRADE 1 -->
    <div class="list-group">
  <a href="#" class="list-group-item active">
    <h2 class="list-group-item-heading"> ඔබට කාලසටහන දැනගැනීමට අවශ්‍ය ශ්‍රේණිය ඇතුළත් කාණ්ඩය තෝරා ක්ලික් කරන්න. </h2>
  </a>   
        
     <div class="w3-container w3-center w3-animate-bottom">
  <marquee width="100%" direction="left" style="color:green;"> <a href="https://wa.me/message/TEZYUZZ4VMJPO1">
  ඔබට පන්ති පිළිබඳ ඕනෑම තොරතුරක් 074 215 4101 අංකයට Whatsapp පණිවිඩයක් යොමු කිරීමෙන් ලබාගත හැක. </a>
  </marquee>
  </div>       

  <a href="1_5" class="list-group-item">
    <h4 class="list-group-item-heading-time">1 ශ්‍රේණියේ සිට 5 ශ්‍රේණිය දක්වා  කාලසටහන</h4>
    <!-- <p class="list-group-item-text">Click Here</p> -->
  </a>

  <a href="6_9" class="list-group-item">
    <h4 class="list-group-item-heading-time">6 ශ්‍රේණියේ සිට 9 ශ්‍රේණිය දක්වා  කාලසටහන</h4>
    <!-- <p class="list-group-item-text">Click Here</p> -->
  </a>

  <a href="ol" class="list-group-item">
    <h4 class="list-group-item-heading-time"> O/L (10,11 ශ්‍රේණි) කාලසටහන</h4>
    <!-- <p class="list-group-item-text">Click Here</p> -->
  </a>

  <a href="al" class="list-group-item">
    <h4 class="list-group-item-heading-time">A/L (12,13 ශ්‍රේණි) කාලසටහන</h4>
    <!-- <p class="list-group-item-text">Click Here</p> -->
  </a>

  <a href="courses" class="list-group-item">
    <h4 class="list-group-item-heading-time"> අනෙකුත් පාඨමාලාවන්ගේ කාලසටහන්</h4>
    <!-- <p class="list-group-item-text">Click Here</p> -->
  </a>


</div>



</div>
</template>

<script>
export default {

}
</script>

<style>

.list-group-item-heading-time, .list-group-item-text {
    text-align: center;
}

</style>
