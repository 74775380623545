<template>
  <div>

    <iframe src="https://docs.google.com/document/d/e/2PACX-1vT3gjM916z3GZF-uEyGt6CLX_0X8rZW9v4eKKFsLgNfcy6u7EALbagKM6cI355JCVoFxU_4t53CYLsE/pub?embedded=true" width="100%" height="650px"></iframe>  

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>