<template>
  <div>

     <iframe src="https://docs.google.com/document/d/e/2PACX-1vRcC4PTOxKeklDFL2sjwT0ggKYrhug49MYFPQcdK1GlqbC9TezHscPXDmFsnxyPB0Lw3j-tbfhTh4AY/pub?embedded=true" width="100%" height="650px"></iframe> 

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>