import { render, staticRenderFns } from "./Ina_pahathata_damu_gawuma.vue?vue&type=template&id=3cced394"
import script from "./Ina_pahathata_damu_gawuma.vue?vue&type=script&lang=js"
export * from "./Ina_pahathata_damu_gawuma.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports