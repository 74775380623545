<template>
    <div>
        <form action="#!" id="main">
            <h2>Module Login</h2>

            <div class="input-parent">
                <label for="username">Username/Code</label>
                <input type="text" id="username" v-model="user.username" />
            </div>

            <div class="input-parent">
                <label for="password">Password</label>
                <input type="password" id="password" v-model="user.password" />
            </div>

            <button type="submit" @click="vaidateUser">Login</button>
        </form>

        <!-- Add Link to notice page -->
                        <router-link to="help">
                        <p class="text-center" style="color:black"> Get help ( පාඨමාලාවට ප්‍රවේශ වීමට උදවු ලබාගන්න ) </p> </router-link>

    </div>
</template>

<script>
    import json from './userDB.json'

    export default {
        data() {
            return({
                users: json, // DB user data
                user: {
                    username: '',
                    password: '',
                }
            })
        },
        methods: {
            /**
             * Validate a user and set cookie for 30MIN
             * used npm package - vue-cookies
             * @returns boolean
             */
            vaidateUser() {
                this.users.map((user) => {
                    // Check equality of un and pw
                    if(this.user.username == user.username && this.user.password == user.password) {
                        // alert('valid user ' + user.username)

                        // Set cookie
                        this.$cookies.set("username", user.username, "10MIN")

                        // Open module page
                        this.$router.push(`/module/${this.user.username}`)

                        return true
                    }
                })

                if(! this.$cookies.isKey("username")) {
                    alert('no valid user in DB ' + this.user.username)
                    return false
                }
                
            }
        }
    }
</script>

<style>
*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    font-family: inherit;
    box-sizing: border-box;
}
#main {
    width: max-content;
    margin: 40px auto;
    font-family: "Segoe UI", sans-serif;
    padding: 25px 28px;
    background: #151414;
    border-radius: 4px;
    border: 1px solid #302d2d;
    animation: popup 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
@keyframes popup {
    0% {
        transform: scale(0.2);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
h2 {
    text-align: center;
    font-size: 28px;
    margin-bottom: 20px;
    font-weight: 400;
    color: #e7e7e7;
}
.input-parent {
    display: block;
    margin-bottom: 20px;
}
label {
    display: block;
    font-size: 16px;
    margin-bottom: 8px;
    color: #a4a4a4;
}
.input-parent input {
    padding: 10px 8px;
    width: 100%;
    font-size: 16px;
    background: #323131;
    border: none;
    color: #c7c7c7;
    border-radius: 4px;
    outline: none;
    transition: all 0.2s ease;
}
.input-parent input:hover {
    background: #404040;
}
.input-parent input:focus {
    box-shadow: 0px 0px 0px 1px #0087ff;
}
button {
    padding: 10px 18px;
    font-size: 15px;
    background: #1a3969;
    width: 100%;
    border: none;
    border-radius: 4px;
    color: #f4f4f4;
    transition: all 0.2s ease;
}
button:hover {
    opacity: 0.9;
}
button:focus {
    box-shadow: 0px 0px 0px 3px black;
}
body {
    background: #1c1b1b;
}

</style>